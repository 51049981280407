import { useState } from "react";
import styles from "./App.module.css";
import{FcSearch} from 'react-icons/fc';
const api = {
  key: "03f12f26cdd0688364eeb7be44d3f0bb",
  base: "https://api.openweathermap.org/data/2.5/",
};

function App() {
  const [search, setSearch] = useState("");
  const [weather, setWeather] = useState({});

  
  const searchPressed = () => {
    //http://api.openweathermap.org/data/2.5/weather?q=London,uk&APPID=03f12f26cdd0688364eeb7be44d3f0bb
    fetch(`${api.base}weather?q=${search}&units=metric&APPID=${api.key}`)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setWeather(result);
      });
  };

  return (
    <div className={styles.App}>
      <div className={styles.header}>
      
        <h1 className={styles.movableheading}>WEATHER</h1>

        
          <input className={styles.cardtitle}
            type="text"
            placeholder="Enter city/town"
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className={styles.searchbutton} onClick={searchPressed}> <FcSearch/> </button>
        
        
         
        {typeof weather.main !== "undefined" ? (
           <div className={styles.searchPressed}>
           
           <div className={styles.card}>
             <p className={styles.carddescription}>Location:      {weather.name}</p>
             <p className={styles.carddescription}>Temperature:  {weather.main.temp}°C</p>
             <p className={styles.carddescription}>Climate:      {weather.weather[0].main}</p>
             <p className={styles.carddescription}>How it is:       ({weather.weather[0].description})</p>
           </div>
         </div>
        ) : (
          <p>Type the Name of the Location in the SearchBar</p>
        )}
      </div>
    </div>
    
  );
}

export default App;